import { View, Dimensions, Image, Platform, Pressable } from 'react-native';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloudText } from '@assets/--native';
import { useQuery } from 'react-query';
import apis from '@api/api';
import LottieView from 'lottie-react-native';
import React from 'react';
import { Title, Text } from '@constants/Themed';
import * as Animatable from 'react-native-animatable';
import * as WebBrowser from 'expo-web-browser';
import { H2, H3, H4, Paragraph, Separator } from 'tamagui';
import HorizontalTextCarousel from '@components/Global/Carousel/HorizontalTextCarousel';
import Carousel from 'react-native-reanimated-carousel';

const DynamicContentInfo = () => {
  const { t } = useTranslation();
  const [carouselData, setCarouselData] = useState();
  const loadingRef = useRef<LottieView>(null);

  useEffect(() => {
    loadingRef.current?.play(0, 200);
  }, [])

  const { status, data, error, isFetching, isLoading, refetch } = useQuery({
    queryKey: ['login-messages'],
    queryFn: async () => {
      const data = await apis.getLoginMessages()
        .then(res => {
          return res
        }).catch(e => {
          console.log(e);
        });
      console.log('carousel data', data);
      setCarouselData(data);
      return data;
    },
    retry: 2
  })

  useEffect(() => {
    // Function to ping the server
    const pingServer = async () => {
      try {
        if (error)
          refetch()
      } catch (e) {
        // Error occurred while pinging the server
        console.error('Error pinging the server', e);
      }
    };

    // Initial play animation
    loadingRef.current?.play(0, 200);

    // Periodically ping the server every minute
    const pingInterval = setInterval(() => {
      pingServer();
    }, 60000);
    if (error) {
      console.log(error);
    }
    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(pingInterval);
  }, [error]); 

  function emailUs() {
    WebBrowser.openBrowserAsync(
      'https://www.smartsaas.co.uk/privacy-policy/'
    )
  }

  const width = Dimensions.get('window').width;
  const [mode, setMode] = useState<any>('horizontal-stack');
  const [snapDirection, setSnapDirection] = useState<'left' | 'right'>(
    'right'
  );
  const [pagingEnabled, setPagingEnabled] = useState<boolean>(true);
  const [snapEnabled, setSnapEnabled] = useState<boolean>(true);
  const [loop, setLoop] = useState<boolean>(true);
  const [autoPlay, setAutoPlay] = useState<boolean>(false);
  const [autoPlayReverse, setAutoPlayReverse] =
    useState<boolean>(false);
  const viewCount = 0;



  return (
    <View style={{ position: "relative", flex: 0.2 }}>
      {
        error ?
          <Animatable.View animation={Platform.OS === 'web' ? 'slideInDown' : 'slideInUp'} style={{ flex: 1, padding: 15, marginBottom: -40, minWidth: 300, minHeight: 160, maxHeight: 250, borderRadius: 20, backgroundColor: 'white', paddingHorizontal: 15, flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
            <LottieView autoPlay style={{ alignSelf: 'center', justifyContent: 'center', flex: 1, padding: 5, maxWidth: 80 }} source={require('@assets/animations/errorIcon.json')} loop={true} speed={1} />
            <View style={{ flexDirection: "row-reverse", flex: 1, paddingHorizontal: 30 }}>
              <View style={{ flex: 10, minHeight: '100%', gap: 5 }}>
                <Title adjustsFontSizeToFit style={{ fontWeight: "bold", lineHeight: 20, marginBottom: 5, fontSize: 16 }}>Our server may be offline</Title>
                <Pressable onPress={() => emailUs()}><Text adjustsFontSizeToFit style={{ fontSize: 20 }} numberOfLines={4}>We apologise for any inconvenience, if this is the first time this error has appeared, try {Platform.OS === 'web' ? "refreshing the page" : "force closing and re-opening the app"}.</Text></Pressable>
              </View>
            </View>
          </Animatable.View>
          :
          <View style={{ flex: 1 }}>
            {
              Platform.OS === 'web' ?
                <HorizontalTextCarousel viewStyle={{ paddingBottom: 20 }} height={130} content={carouselData} />
                :
                <Carousel
                  loop
                  width={width - (width / 3.5)}
                  height={110}
                  autoPlay={true}
                  data={carouselData}
                  pagingEnabled={pagingEnabled}
                  autoPlayInterval={6000}
                  snapEnabled={snapEnabled}
                  style={{ flex: 1, overflow: 'visible', margin: 25, top: -20, justifyContent: 'center', paddingLeft: 50 }}
                  mode={'horizontal-stack'}
                  modeConfig={{
                    snapDirection,
                    stackInterval: mode === 'vertical-stack' ? 8 : 35,
                  }}
                  renderItem={({ index, item }) => (
                    <View
                      style={{
                        justifyContent: 'center',
                        paddingVertical: 5,
                        paddingHorizontal: 20,
                        borderRadius: 20,
                        overflow: 'visible',
                        flex: 1,
                        backgroundColor: 'white'
                      }}
                    >
                      <H4 style={{ maxHeight: 30, fontSize: 14, fontWeight: '600' }}>{t(item.title)}</H4>
                      <Separator style={{ marginBottom: 4 }} />
                      <Text style={{ fontSize: 12, lineHeight: 13, paddingRight: 40, marginBottom: 5 }}>{t(item.descriptor)}</Text>
                    </View>
                  )}
                />
            }
          </View>
      }
    </View>
  )
}
export default DynamicContentInfo;