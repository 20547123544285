import * as AppleAuthentication from 'expo-apple-authentication';
import { View, Platform, StyleSheet } from 'react-native';
import { SHOW_AUTH_MODAL, SUCCESS } from '@reduxLocal/actions/types';
import { DefaultRootState } from '@reduxLocal/persistState';
import { navigationRef } from '../../../../rootNavigation';
import apis from '@api/api';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

const AppleButton = () => {
  const useNavigation = navigationRef;
  const notificationToken = useSelector(
    (state: DefaultRootState) => state.app.notificationToken
  );
  const dispatch = useDispatch();
  
  return Platform.OS !='web' && (
    <View style={{ maxHeight: 50, flex: 1, elevation: 1, shadowRadius: 10, shadowOpacity: .2, backgroundColor: 'white', borderRadius: 30, paddingHorizontal: 20, flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'stretch', }}>
      <AppleAuthentication.AppleAuthenticationButton
        buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
        buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.WHITE}
        cornerRadius={15}
        style={{ flex: 1 }}
        onPress={async () => {
          try {
            const credential = await AppleAuthentication.signInAsync({
              requestedScopes: [
                AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                AppleAuthentication.AppleAuthenticationScope.EMAIL,
              ],
            });

            const newOAuthUser = {
              surname: credential.fullName?.familyName,
              name: credential.fullName?.givenName,
              authId: credential.user,
              email: credential.email,
              oauthToken: credential.identityToken,
              type: 'apple'
            }
            await apis.handleOAuth(newOAuthUser)
              .then((res) => {
                if (res.success) {
                  let amendedData = res.data;
                  const prepend = 'data:image/png;base64,';
                  try {
                    amendedData.userData.avatar.img =
                      prepend + res.data.userData.avatar.img;
                    amendedData.userData.avatar.smallImg =
                      prepend + res.data.userData.avatar.smallImg;
                    amendedData.userData.dashboardImage.img =
                      prepend +
                      amendedData.userData.dashboardImage.img;
                  } catch (e) {
                    dispatch({
                      type: SHOW_AUTH_MODAL,
                      data: {
                        content: 'Could not retrieve desk image',
                        type: 'warning',
                      },
                    });
                  }
                  if (!amendedData.notication_token) {
                    apis.updateNotificationToken(
                      notificationToken,
                      amendedData._id
                    );
                  }
                  dispatch({ type: SUCCESS, data: amendedData })
                  useNavigation.navigate('Dashboard')
                }
              })
            // signed in
          } catch (e) {
            if (e.code === 'ERR_REQUEST_CANCELED') {
              dispatch({ type: SHOW_AUTH_MODAL, content: { error: 'Cancelled by user', type: 'error' } })
            } else {
              console.log(e)
              dispatch({ type: SHOW_AUTH_MODAL, data: { content: e.toString(), type: 'error' } })
            }
          }
        }}
      />
    </View>
  )
}
export default AppleButton