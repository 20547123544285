import { Dimensions, Platform, ScrollView, View, useWindowDimensions, Pressable } from 'react-native';
import React, { useEffect, useRef, useState, memo, useCallback, Suspense, useContext } from "react";
import styles from "@stylesheet";
import Menu from "../Menu/Menu";
import Messages from "../screens/MidLevel/MessageScreen/Messages";
import LangModal from "../../components/modals/LanguageModal/LangModal";
import HelpModal from "../../components/modals/HelpModal/HelpModal";
import AchievementModal from "../../components/modals/AchievementModal/AchievementModal";
import MessageModal from "../../components/modals/ErrorModal/MessageModal";
import AddCompanyModal from "../../components/modals/AddCompanyModal/AddCompanyModal";
import CommModal from "../../components/modals/CommunityModal/CommModal";
import FooterBar from "../FooterBar/FooterBar";
// import AIHandler from "../AIHandler/AIHandler";
import FeedbackModal from "../../components/modals/FeedbackModal/FeedBackModal";
import NotificationModal from '../../components/modals/NotificationModal/NotificationModal';
import { ToolbarContext } from '@hooks/toolbarContext';
import MailLoginModal from '../screens/MidLevel/MailScreen/Components/MailLoginModal';
import UpdateMessageModal from './Components/UpdateMessageModal';
import apis from '@api/api';
import { useQuery, useQueryClient } from 'react-query';
import HeaderBar from '@navigation/HeaderBar/HeaderBar';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_NOTIFICATIONS, SHOW_AUTH_MODAL } from '@reduxLocal/actions/types';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import LogViewer from '@navigation/logViewer';
import Terminal from 'react-console-emulator'
import { clearDB } from '@reduxLocal/persistState';
import { useNavigation } from '@react-navigation/native';
import { connect } from 'react-redux'
import NetInfo from '@react-native-community/netinfo';
import LottieView from 'lottie-react-native';
import PackageModal from '../../navigation/NavigationStacks/Components/PackageModal';
import { Paragraph } from 'tamagui';
import { ColorSchemeContext } from '../../../App';
import { Text } from '@constants/Themed';

const TutorialModal = React.lazy(() => import('@components/modals/TutorialModal/TutorialModal'));
const DashboardStack = React.lazy(() => import('./DashboardStack'));

const DashboardNavigator = ({ headerState }) => {
  const user = useSelector((state: any) => state.promiseData.data.userData);
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const isConnected = NetInfo.fetch().then(state => state.isConnected);

  if (!user.id) {
    navigation.navigate('Login');
    dispatch({ type: SHOW_AUTH_MODAL, data: { content: "Please login to continue", type: "warning" } });
    return;
  }

  if (!isConnected) {
    navigation.navigate('Login');
    dispatch({ type: SHOW_AUTH_MODAL, data: { content: "No internet connection", type: "warning" } });
    return;
  }

  //Determine width of overall app
  const [ref, setRef] = useState(null);
  const updatePending = false;
  const [optionsRef, setOptionsRef] = useState(null);
  const [reportRef, setReportRef] = useState(null);
  const [calendarContext, setCalendarContext] = useState(null);
  const [hierarchySocket, setHierarchySocket] = useState(null);
  const [projectContext, setProjectContext] = useState(null);
  const [calendarHeader, setCalendarHeader] = useState(null);
  const [draggedItem, setDraggedItem] = useState(null);
  const toolbar = { calendarHeader, setCalendarHeader, calendarContext, setCalendarContext, hierarchySocket, setHierarchySocket, draggedItem, setDraggedItem, ref, setRef, optionsRef, setOptionsRef, reportRef, setReportRef, projectContext, setProjectContext };
  const theme = themeSelector();
  const logRef = useRef(null);
  const [logCount, setLogCount] = useState(0); //Initialise log count
  const [logVisible, setLogVisible] = useState(false);
  const { colorScheme } = useContext(ColorSchemeContext);
  //Initialise app with no notifications
  // dispatch({ type: CLEAR_NOTIFICATIONS });

  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ['invitations'],
    queryFn: async () => {
        const res = await apis.getInvitations();
        return res;
      },
      staleTime: 10000,  // Optional: control how long the data is fresh,
      retry: 3
    });

  useEffect(() => {
    if (logRef.current) {
      logRef.current?.refresh();
      setLogCount(logRef.current?.getLogCount());
    }
  }, [])

  return (
      <ToolbarContext.Provider value={toolbar}>
        <View style={{ flexDirection: "row", flexGrow: 1, maxHeight: "100%", backgroundColor: theme ? undefined : "#0e1111" }}>
          {
          logVisible && Platform.OS === 'web' &&
            <View style={{ minWidth: 450, flex: 0.1 }}>
              <View style={{ flex: 8, maxHeight: Dimensions.get('window').height * 0.7, padding: 10 }}>
                <LogViewer ref={logRef} />
              </View>
              <View style={{ height: 40, flexDirection: "row" }}>
                <Pressable onPressIn={() => logRef.current!.clear()} style={{ flex: 1, backgroundColor: 'black', justifyContent: 'center', alignItems: 'center' }} onPress={() => { }}>
                  <Text selectable={false} style={{ color: 'white' }}>Clear</Text>
                </Pressable>
                <Pressable onPress={() => logRef.current!.refresh()} style={{ flex: 1, backgroundColor: 'black', justifyContent: 'center', alignItems: 'center' }} onPress={() => { }}>
                  <Text selectable={false} style={{ color: 'white' }}>Refresh</Text>
                </Pressable>
              </View>
              <View style={{ flex: 2 }}>
                <Terminal
                  commands={{
                    echo: {
                      description: 'Echo a passed string',
                      usage: 'echo <string>',
                      fn: function () {
                        return `${Array.from(arguments).join(' ')}`
                      }
                    },
                    help: {
                      description: 'List available commands',
                      fn: function () {
                        return Object.keys(this.commands).join(', ')
                      }
                    },
                    list: {
                      description: 'List all logs',
                      fn: async function () {
                        logRef.current!.refresh();
                        return `Log list refreshed`
                      }
                    }
                  }}
                  noDefaults
                  welcomeMessage={'Connected to SmartSaaS terminal'}
                  promptLabel={'> '}
                  style={{
                    flex: 1,
                    height: '70%',
                    width: '100%'
                  }} />
              </View>
            </View>
          }
          <View style={{ flex: 1, backgroundColor:"transparent", flexDirection: "row" }}>
            <Menu />
          <View style={{ zIndex: -1, flexDirection: 'column', flex: 10, overflow: 'hidden', width: '100%' }}>
              <NotificationModal />
              <HeaderBar />
              {/*Modals go here */}
              <MessageModal />
            {/* Replace logic to set update pending in redux state */}
            <UpdateMessageModal hasUpdatePending={updatePending} callback={() => console.log('hi')} />
            <Suspense fallback={<Text>Loading Tutorial...</Text>}>
              <TutorialModal />
            </Suspense>
              <AchievementModal />
            <PackageModal />
              <MailLoginModal />
              <View style={[styles.appContainer]}>
                <View style={[styles.contentContainer]}>
                  <View style={{ flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: "center" }}>
                    <View style={{ flex: 2, paddingTop: 70, justifyContent: 'center', alignSelf: 'stretch', alignContent: 'center', flexGrow: 5, marginRight: -50, flexShrink: 1, paddingHorizontal: 80 }} >
                    <Suspense fallback={
                      <View style={[styles.centerEverything, { justifyContent: 'center', minHeight: "100%", flex: 1 }]}>
                        <LottieView source={require('@assets/animations/spin-leaf-loader.json')} autoPlay loop style={{ flex: 1, maxHeight: 150 }} />
                        <Paragraph>Loading Dashboard...</Paragraph>
                      </View>
                    }>
                      <DashboardStack />
                    </Suspense>
                    </View>
                  </View>
                  <HelpModal />
                </View>
              </View>
              <View style={{ flexDirection: 'column-reverse', }}>
                <View style={[styles.centerEverything, { maxHeight: 0, backgroundColor: "transparent", alignSelf: 'stretch', flex: 1, flexDirection: 'row', minWidth: '100%', paddingHorizontal: '20%', top: 40 }]}>
                  <FooterBar />
                  <AddCompanyModal />
                  <LangModal />
                  <CommModal />
                  <FeedbackModal />
                </View>
              </View>
            </View >
            <Messages />
        </View>
        </View>
      </ToolbarContext.Provider>
  );
};

export default DashboardNavigator;