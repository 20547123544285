import React, { useState, useEffect, memo, useCallback, forwardRef, useRef, useImperativeHandle, ForwardedRef } from 'react';
import * as Animatable from 'react-native-animatable';
import { View, Image, useWindowDimensions, Pressable, Platform, StyleSheet } from 'react-native';
import styles from '@stylesheet';
import { FieldHelperProps, FieldInputProps, FieldMetaProps, useFormikContext } from 'formik';
import moment from 'moment';
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates';
import { Switch } from 'react-native-paper';
import DropDownPicker from 'react-native-dropdown-picker';
import toTitleCase from './toTitleCase';
import TagInput from '../components/Global/TagInput';
import { Modal, Text } from '@constants/Themed';
import InputFieldStyled from '@components/Global/InputFieldStyled';
import ImagePickerComponent, { ImagePickerHandle } from '../components/formComponents/ImageSelection';
import Button from '../components/UI/Button/Button';
// import DatePicker from 'react-native-date-picker';
import parseByDelimiter from '../utils/parseByDelimiter';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import { Entypo } from '@expo/vector-icons';
import { GooglePlacesAutocomplete, GooglePlacesAutocompleteRef } from 'react-native-google-places-autocomplete';
import { USER_API_BASE_URL } from '@api/constant';
import { AntDesign } from '@expo/vector-icons';
import { useField } from 'formik';
import Checkbox from 'expo-checkbox';
import { debounce } from 'lodash';
import UserFinder from '@components/Global/UserFinder/UserFinder';
import CurrencyExtractor from '@utils/stringUtils/currencyList';
import apis from '@api/api';
import CRMList from '@components/Global/CRMFinder/CRMList';
import TemplateListItem from '@components/Global/ListTypes/TemplateListItem';
import { TextField } from '@mui/material';
import { DateField, DatePicker, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import genCountryList from './genCountryList';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css'

const countryList = genCountryList();

interface GenerateGetCityProps {
  index: number;
  length: number;
  nextFieldFunction: (index: number, length: number) => void;
  meta: FieldMetaProps<string>;
  helpers: FieldHelperProps<string>;
  field: FieldInputProps<string>;
  focusCallback?: Function;
  otherVals?: Object;
  blurCallback?: Function;
  theme: string; // Assuming theme is a string, update accordingly
  hasError: boolean;
  schemaItem: {
    type: string;
    content: {
      helpText: string;
    };
  };
  updateState: (value: string) => void;
  updateFunction: (type: string, value: string) => void;
  initialValue?: string;
}

const GenerateGetCity = forwardRef<GooglePlacesAutocompleteRef, GenerateGetCityProps>(
  (
    { index, focusCallback, blurCallback, length, nextFieldFunction, helpers, field, theme, hasError, schemaItem, updateFunction, initialValue }: GenerateGetCityProps,
    ref
  ) => {
    const [focused, setFocused] = useState(false);
    return (
      <View style={{ flex: 1, left: -15 }}>
        {
          schemaItem.content?.helpText &&
          <View style={{ flexDirection: 'row', gap: 10, marginVertical: 5 }}>
            <AntDesign name="questioncircle" size={14} color={theme ? 'black' : 'white'} />
            <Text>{schemaItem.content?.helpText}</Text>
          </View>
        }
        <GooglePlacesAutocomplete
          query={{
            key: 'AIzaSyAznGh_h4mH0meG5V6hlTlLVhVithzlUJM',
            langauge: 'en'
          }}
          ref={ref}
          placeholder={parseByDelimiter(schemaItem.title) || "Address"}
          onFail={() => { helpers.setTouched(true, true); helpers.setValue(''); helpers.setError('Please enter a valid address') }}
          onPress={(data, details = null) => {
            helpers.setValue(data.description);
            updateFunction(schemaItem.title, data.description);
            setFocused(false);
            nextFieldFunction(index, length);
          }}
          isRowScrollable={false}
          requestUrl={{
            useOnPlatform: 'web',
            url: focused && `${USER_API_BASE_URL}/proxy/google-map-proxy`,
          }}
          debounce={focused ? 200 : null}
          styles={{ flex: 1, maxWidth: "100%", alignSelf: "stretch", zIndex: 10, listView: { overflow: "hidden", flex: 1, borderWidth: 1, left: 15, minHeight: 70, borderRadius: 5, maxWidth: Platform.select({ web: 400, default: 300 }) } }}
          textInputProps={{
            // value: initialValue ? field.value : null,
            ref: ref,
            style: { ...styles.inputOutline, borderColor:"#84808180", maxHeight: undefined, minHeight: 55, backgroundColor: theme ? 'white' : 'grey', minWidth: '100%', color: theme ? 'black' : 'white' },
            label: parseByDelimiter(schemaItem.title) || "Address",
            onFocus: () => {
              setFocused(true);
              if (focusCallback) focusCallback();
            },
            onBlur: () => {
              setFocused(false);
              if (blurCallback) blurCallback();
            },
            placeholderTextColor: theme ? 'grey' : 'white',
            enablesReturnKeyAutomatically: true,
            container: { flex: 1, minHeight: 60, maxWidth: "100%" },
            enterKeyHint: "next",
            leftIcon: { type: 'font-awesome', name: 'chevron-left' },
            errorStyle: { color: 'red' },
          }}
        />
      </View>
    )
  })

interface HandleFieldTypeTypes {
  fieldProps: any,
  buttonColor?: string,
  schemaItem: any,
  index: number,
  length: number,
  nextFieldFunction: Function,
  setRef?: any,
  focusCallback?: Function,
  blurCallback?: Function,
  customStyle?: Object,
  initialValue?: any,
  errorCallback?: Function,
  otherVals?: Object
}

const DROPDOWN_HEIGHT = 300;

const HandleFieldType = (props: HandleFieldTypeTypes) => {
  const {
    fieldProps: { name, onBlur, onChange, value, setFieldValues, error },
    buttonColor, schemaItem, index, length, nextFieldFunction, otherVals, errorCallback
  } = props;

  const [field, meta, helpers] = useField(name);
  const [dateSelected, setDateSelected] = useState(moment());
  const [date, setDate] = useState(moment());
  const [pickerOpen, setPickerOpen] = useState(false);
  const [city, setCity] = useState('');
  const [pickerState, setPickerState] = useState();
  const [tags, setTags] = useState(value);
  const [open, setOpen] = React.useState(false);
  const [time, setTimeOpen] = useState(false);
  const [timeSelected, setTimeSelected] = useState(value);
  const [selectedImage, setSelectedImage] = useState(null);
  const [barcode, setBarcode] = useState(false);
  const { width } = useWindowDimensions();
  const [switchState, setSwitchState] = useState(value);
  const [localText, setLocalText] = useState(value);
  const theme = themeSelector();
  const [dateModal, setDateModal] = useState(false);
  const [height, setHeight] = useState(0);
  const pickRef = useRef<ImagePickerHandle>(null);

  const hasError = meta.error && meta.touched;

  useEffect(()=>{
    if(hasError){
      errorCallback && errorCallback?.(index, meta.error)
    }
  },[hasError])

  const inputRef = useRef(null);

  useEffect(() => {
    props.setRef(name, inputRef);
    return () => {
      // Clean up inputRef
      inputRef.current = null;
      pickRef.current = null;
    };
  }, [name, props.setRef]);


  const handleKeyPress = (index) => {
    nextFieldFunction(index, length);
    if (props.blurCallback) props.blurCallback();
  };
  const onDismissSingle = React.useCallback(() => {
    helpers.setValue(dateSelected);
    props.blurCallback?.();
    helpers.setTouched(true, true);
    setOpen(false);
  }, [field, date, setOpen]);

  const handleImageSelected = async (img, type) => {
    setSelectedImage(img.uri);
    if (type === "avatar") {
      helpers.setValue({ img: img.base64 });
    } else {
    helpers.setValue(img.base64);
    }
    // Perform any additional action with the selected image uri here
  }

  const handleSwitchChange = (value) => {
    setSwitchState(value);
    helpers.setValue(value)
  }

  const handleTimeChange = (value) => {
    setTimeSelected(value);
    const { hours, minutes } = value;
    //Append time to the date selected
    const date = new Date(dateSelected);
    date.setHours(hours);
    date.setMinutes(minutes);
    helpers.setValue(date);
  }

  const onConfirmSingle = (params) => {
    helpers.setValue(params);
    helpers.setTouched(true, true);
    setDateSelected(params);
    setOpen(false);
  }

  const _helperCallback = (value) => {
    helpers.setValue(value);
  }

  const handleDateSelection = (type, date) => {
    helpers.setValue(date);
    setDateSelected(date);
    setOpen(false);
    helpers.setTouched(true, true);
  }

  const handleUpdateTags = (type) => {
    setTags(type);
    helpers.setValue(tags)
  }

  const handleUpdateCity = (type, value) => {
    helpers.setValue(value);
    helpers.setTouched(true, true);
  }

  // Special CASES
  if (schemaItem.type === 'avatar' || schemaItem.title === 'avatar') {
    return (
      <Animatable.View duration={100} transition={['flex', 'marginBottom', 'top']} style={{ flex: 1, marginBottom: height }}>
        <View style={{ flexDirection: 'row', flex: 1 }}>
          <Text style={[fieldStyles.typeStyle, { textAlign: "center" }]}>{parseByDelimiter(schemaItem.title)}</Text>
          {hasError && <Animatable.Text animation={'fadeInLeft'} style={{ color: 'red', zIndex: -1, padding: 2 }}>{meta.error}</Animatable.Text>}
        </View>
        {
          schemaItem.content?.helpText &&
          <View style={{ flexDirection: 'row', gap: 10, marginVertical: 5 }}>
            <AntDesign name="questioncircle" size={14} color={theme ? 'black' : 'white'} />
            <Text style={{ flex: 1 }}>{schemaItem.content?.helpText}</Text>
          </View>
        }
        {
          field.value?.img ?
            <View style={[styles.centerEverything, { gap: 30 }]}>
              <Image source={{ uri: selectedImage }} style={{ width: 100, height: 100, borderRadius: 30 }} />
              <Button icon="user" customStyle={{ minWidth: 300 }} buttonText={'Change Image'} onPress={() => { helpers.setValue(null); pickRef.current!.pickImage() }} />
            </View>
            :
            <ImagePickerComponent ref={pickRef} onImageSelected={(img) => handleImageSelected(img, "avatar")} />
        }
      </Animatable.View>
    )
  }

  switch (schemaItem.ref || schemaItem.type) {
    case 'country':
      const [country, setCountry] = useState(value);

      return (
        <Animatable.View duration={100} transition={['flex', 'marginBottom', 'top']} style={{ flex: 1, marginVertical: 10, marginBottom: height }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={fieldStyles.typeStyle}>{parseByDelimiter(schemaItem.title)}</Text>
            {hasError && <Animatable.Text animation={'fadeInLeft'} style={{ color: 'red', zIndex: -1, padding: 2 }}>{meta.error}</Animatable.Text>}
          </View>
          {
            schemaItem.content?.helpText &&
            <View style={{ flexDirection: 'row', gap: 10, marginVertical: 5 }}>
              <AntDesign name="questioncircle" size={14} color={theme ? 'black' : 'white'} />
              <Text style={{ flex: 1 }}>{schemaItem.content?.helpText}</Text>
            </View>
          }
          <DropDownPicker
            items={countryList}
            open={pickerOpen}
            onOpen={() => setHeight(DROPDOWN_HEIGHT)}
            onClose={() => setHeight(0)}
            zIndex={3}
            searchable={true}
            dropDownContainerStyle={{ flex: 1, borderColor: '#4791bd' }}
            dropDownDirection="AUTO"
            setOpen={setPickerOpen}
            value={pickerState}
            setValue={(value) => { setPickerState(value); helpers.setValue(value) }}
            placeholder='Country'
            style={{ flex: 1, backgroundColor: theme ? 'white' : 'grey' }}
            containerStyle={{ flex: 1, minWidth: 100 }}
          />
        </Animatable.View>
      )
    case 'product_select':
      const [productsLoading, setProductsLoading] = useState(false);
      const [products, setProducts] = useState([]);

      const handleProductOpen = async () => {
        setProductsLoading(true);
        setHeight(DROPDOWN_HEIGHT);
        await apis.getProductServiceList().then((res) => {
          setProducts(res);
        }).finally(() => {
          setProductsLoading(false);
        })
      }

      const handleProductSearch = async (text) => {
        setProductsLoading(true);
        const filtered = products?.filter?.(item => item?.label?.toLowerCase().includes(text.toLowerCase()));
        setProducts(filtered);
        setProductsLoading(false);
      }

      return (
        <Animatable.View duration={100} transition={['flex', 'marginBottom', 'top']} style={{ flex: 1, alignContent: "flex-start", justifyContent: "flex-start", top: height > 0 ? -20 : -20, marginBottom: height }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[fieldStyles.typeStyle, { top: 20 }]}>{parseByDelimiter(schemaItem.title)}</Text>
            {hasError && <Animatable.Text animation={'fadeInLeft'} style={{ color: 'red', zIndex: -1, padding: 2 }}>{meta.error}</Animatable.Text>}
          </View>
          {
            schemaItem.content?.helpText &&
            <View style={{ flexDirection: 'row', gap: 10, marginVertical: 5 }}>
              <AntDesign name="questioncircle" size={14} color={theme ? 'black' : 'white'} />
              <Text style={{ flex: 1 }}>{schemaItem.content?.helpText}</Text>
            </View>
          }
          <DropDownPicker
            onOpen={async () => await handleProductOpen()}
            onClose={() => setHeight(0)}
            zIndex={3}
            showTickIcon
            onChangeSearchText={handleProductSearch}
            searchable={true}
            searchPlaceholder='Search for products'
            searchPlaceholderTextColor='grey'
            multiple={true}
            mode="BADGE"
            closeAfterSelecting={true}
            setItems={setProducts}
            renderListItem={(props) => (<TemplateListItem icon={'shoppingcart'} {...props} />)}
            loading={productsLoading}
            dropDownContainerStyle={{ flex: 1, maxHeight: 350, borderColor: '#4791bd' }}
            dropDownDirection="AUTO"
            key={schemaItem.title + products.length}
            style={{ backgroundColor: "white", marginVertical: 20, borderColor: '#4791bd' }}
            items={products}
            value={pickerState}
            setValue={(value) => { setPickerState(value); }}
            onSelectItem={_helperCallback}
            open={pickerOpen}
            setOpen={setPickerOpen} />
        </Animatable.View>
      );
    case 'sign':
      return (
        <SignComponent {...props} />
      )
    case 'template_select':
      const [templatesLoading, setTemplatesLoading] = useState(false);
      const [templates, setTemplates] = useState([]);

      const handleTemplateOpen = async () => {
        setTemplatesLoading(true);
        setHeight(DROPDOWN_HEIGHT);
        await apis.getTemplates().then((res) => {
          setTemplates(res.templates);
        }).finally(() => {
          setTemplatesLoading(false);
        })
      }

      const handleTemplateSearch = async (text) => {
        setTemplatesLoading(true);
        const filtered = templates?.filter?.(item => item?.label?.toLowerCase().includes(text.toLowerCase()));
        setTemplates(filtered);
        setTemplatesLoading(false);
      }

      return (
        <Animatable.View duration={100} transition={['flex', 'marginBottom', 'top', 'height']} style={{ flex: 1, alignContent: "flex-start", justifyContent: "flex-start", marginBottom: height }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[fieldStyles.typeStyle, { left: -5 }]}>{parseByDelimiter(schemaItem.title)}</Text>
            {hasError && <Animatable.Text animation={'fadeInLeft'} style={{ color: 'red', zIndex: -1, padding: 2 }}>{meta.error}</Animatable.Text>}
          </View>
          {
            schemaItem.content?.helpText &&
            <View style={{ flexDirection: 'row', gap: 10, marginVertical: 5 }}>
              <AntDesign name="questioncircle" size={14} color={theme ? 'black' : 'white'} />
              <Text style={{ flex: 1 }}>{schemaItem.content?.helpText}</Text>
            </View>
          }
          <DropDownPicker
            onOpen={async () => await handleTemplateOpen()}
            onClose={() => setHeight(0)}
            zIndex={3}
            showTickIcon
            onChangeSearchText={handleTemplateSearch}
            searchable={true}
            searchPlaceholder='Search for templates'
            searchPlaceholderTextColor='grey'
            // multiple={true}
            mode="BADGE"
            closeAfterSelecting={true}
            setItems={setTemplates}
            renderListItem={(props) => (<TemplateListItem {...props} />)}
            loading={templatesLoading}
            dropDownContainerStyle={{ flex: 1, maxHeight: 350, borderColor: '#4791bd' }}
            dropDownDirection="AUTO"
            key={schemaItem.title + templates.length}
            style={{ backgroundColor: "white", borderColor: '#4791bd' }}
            items={templates}
            value={pickerState}
            setValue={(value) => { setPickerState(value); }}
            onSelectItem={_helperCallback}
            open={pickerOpen}
            setOpen={setPickerOpen} />
        </Animatable.View>
      )
    case 'crm_item_select':
      const [crmItemsLoading, setCRMItemsLoading] = useState(false);
      const [crmItems, setCRMItems] = useState([]);


      const handleCRMItemOpen = async () => {
        setCRMItemsLoading(true);
        setHeight(DROPDOWN_HEIGHT);
        await apis.getCrmItems(otherVals?._id).then((res) => {
          //Convert CRM items to the format required by the dropdown picker
          res = res.map((item) => {
            console.log(item, 'customer data')
            if (item.data.name && item.data.surname) {
              return {
                ...item,
                label: item.data.name + " " + item.data.surname,
                value: item._id
              }
            }
          })
          setCRMItems(res);
        }).finally(() => {
          setCRMItemsLoading(false);
        })
      }

      const handleCRMItemSearch = async (text) => {
        setCRMItemsLoading(true);
        const filtered = crmItems?.filter?.(item => item?.label?.toLowerCase().includes(text.toLowerCase()));
        setCRMItems(filtered);
        setCRMItemsLoading(false);
      }

      return (
        <Animatable.View duration={100} transition={['flex', 'marginBottom', 'top']} style={{ flex: 1, alignContent: "flex-start", justifyContent: "flex-start", top: height > 0 ? -20 : -20, marginBottom: height }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[fieldStyles.typeStyle, { top: 20 }]}>{parseByDelimiter(schemaItem.title)}</Text>
            {hasError && <Animatable.Text animation={'fadeInLeft'} style={{ color: 'red', zIndex: -1, padding: 2 }}>{meta.error}</Animatable.Text>}
          </View>
          {
            schemaItem.content?.helpText &&
            <View style={{ flexDirection: 'row', gap: 10, marginVertical: 5 }}>
              <AntDesign name="questioncircle" size={14} color={theme ? 'black' : 'white'} />
              <Text style={{ flex: 1 }}>{schemaItem.content?.helpText}</Text>
            </View>
          }
          <DropDownPicker
            onOpen={async () => await handleCRMItemOpen()}
            onClose={() => setHeight(0)}
            zIndex={3}
            showTickIcon
            onChangeSearchText={handleCRMItemSearch}
            searchable={true}
            searchPlaceholder='Search for CRM Items'
            searchPlaceholderTextColor='grey'
            multiple={false}
            mode="BADGE"
            closeAfterSelecting={true}
            setItems={setCRMItems}
            renderListItem={(props) => (<TemplateListItem icon={"user"} {...props} />)}
            loading={crmItemsLoading}
            dropDownContainerStyle={{ flex: 1, borderColor: '#4791bd' }}
            dropDownDirection="AUTO" key={schemaItem.title}
            style={{ backgroundColor: "white", marginVertical: 20, borderColor: '#4791bd' }}
            items={crmItems}
            value={pickerState}
            setValue={(value) => {
              try { setPickerState(value) } catch (e) { console.error(e) }
            }}
            onSelectItem={_helperCallback}
            open={pickerOpen}
            setOpen={setPickerOpen} />
        </Animatable.View>
      )
    case 'customer_datas':
      const [crmLoading, setCrmLoading] = useState(false);
      const [crmList, setCRMList] = useState([]);
      const [filteredList, setFilteredList] = useState([]);

      const handleCRMOpen = async () => {
        setCrmLoading(true);
        setHeight(DROPDOWN_HEIGHT);
        await apis.getCRMFolders().then((res) => {
          setCRMList(res.dataSets);
          setFilteredList(res);
        }).finally(() => {
          setCrmLoading(false);
        })
      }

      const handleSearch = async (text) => {
        setCrmLoading(true);
        const filtered = crmList?.filter(item => item.label.toLowerCase().includes(text.toLowerCase()));
        setFilteredList(filtered);
        setCrmLoading(false);
      }
      return (
        <Animatable.View duration={100} transition={['flex', 'marginBottom', 'top']} style={{ flex: 1, marginBottom: height, maxWidth: "100%" }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={fieldStyles.typeStyle}>{parseByDelimiter(schemaItem.title)}</Text>
            {hasError && <Animatable.Text animation={'fadeInLeft'} style={{ color: 'red', zIndex: -1, padding: 2 }}>{meta.error}</Animatable.Text>}
          </View>
          {
            schemaItem.content?.helpText &&
            <View style={{ flexDirection: 'row', gap: 10, marginVertical: 5 }}>
              <AntDesign name="questioncircle" size={14} color={theme ? 'black' : 'white'} />
              <Text style={{ flex: 1 }}>{schemaItem.content?.helpText}</Text>
            </View>
          }
          <DropDownPicker
            onOpen={async () => await handleCRMOpen()}
            onClose={() => setHeight(0)}
            zIndex={3}
            showTickIcon
            onChangeSearchText={handleSearch}
            searchPlaceholder='Search for customer data'
            searchPlaceholderTextColor='grey'
            multiple={schemaItem.content?.multiple || false}
            mode="BADGE"
            renderListItem={(props) => (<CRMList {...props} />)}
            loading={crmLoading}
            dropDownContainerStyle={{ flex: 1, borderColor: '#4791bd' }}
            dropDownDirection="AUTO" key={schemaItem.title}
            style={{ backgroundColor: "white", borderColor: '#4791bd' }}
            items={crmList}
            value={pickerState}
            setValue={(value) => {
              console.log(value, 'value')
              if (schemaItem.content?.multiple) {
                setPickerState(list => list.push(value));
              } else {
                try { setPickerState(value) } catch (e) { console.error(e) }
              }
            }}
            onSelectItem={_helperCallback}
            open={pickerOpen}
            setOpen={setPickerOpen} />
        </Animatable.View>
      )
      case 'team':
      return (
        <Animatable.View duration={100} transition={['flex', 'marginBottom', 'top']} style={{ flex: 1, top: height > 0 ? -100 : -20, marginBottom: height }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={fieldStyles.typeStyle}>{parseByDelimiter(schemaItem.title)}</Text>
            {hasError && <Animatable.Text animation={'fadeInLeft'} style={{ color: 'red', zIndex: -1, padding: 2 }}>{meta.error}</Animatable.Text>}
          </View>
          {
            schemaItem.content?.helpText &&
            <View style={{ flexDirection: 'row', gap: 10, marginVertical: 5 }}>
              <AntDesign name="questioncircle" size={14} color={theme ? 'black' : 'white'} />
              <Text style={{ flex: 1 }}>{schemaItem.content?.helpText || ""}</Text>
            </View>
          }
          <UserFinder onOpen={() => undefined} onClose={() => undefined} forceSingle={true} forceRow removePermission selected={value} setCallback={_helperCallback} />
        </Animatable.View>
      )
    case 'user_stores':
      return (
        <Animatable.View duration={100} transition={['flex', 'marginBottom', 'top']} style={{ flex: 1, top: height > 0 ? -100 : -20, marginBottom: height, marginTop: 40 }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={fieldStyles.typeStyle}>{parseByDelimiter(schemaItem.title)}</Text>
            {hasError && <Animatable.Text animation={'fadeInLeft'} style={{ color: 'red', zIndex: -1, padding: 2 }}>{meta.error}</Animatable.Text>}
          </View>
          {
            schemaItem.content?.helpText &&
            <View style={{ flexDirection: 'row', gap: 10, marginVertical: 5 }}>
              <AntDesign name="questioncircle" size={14} color={theme ? 'black' : 'white'} />
              <Text style={{ flex: 1 }}>{schemaItem.content?.helpText|| "Select a user"}</Text>
            </View>
          }
          <UserFinder onOpen={() => undefined} onClose={() => undefined} forceSingle={true} forceRow removePermission selected={value} setCallback={_helperCallback} />
        </Animatable.View>
      )
    case 'currency':
      const currencyList = CurrencyExtractor.extract();
      return (
        <Animatable.View duration={100} transition={['flex', 'marginBottom', 'top']} style={{ flex: 1, top: height > 0 ? -100 : -20, marginBottom: height }}>
          <View style={{ flex: 1, flexDirection: 'row', gap: 3 }}>
            <Text style={fieldStyles.typeStyle}>{parseByDelimiter(schemaItem.title)}</Text>
            {hasError && <Animatable.Text animation={'fadeInLeft'} style={{ color: 'red', zIndex: -1, padding: 2 }}>{meta.error}</Animatable.Text>}
          </View>
          {
            (schemaItem.content?.helpText || schemaItem.content?.validation?.helpText) &&
            <View style={{ flexDirection: 'row', gap: 10, marginVertical: 5 }}>
              <AntDesign name="questioncircle" size={14} color={theme ? 'black' : 'white'} />
              <Text style={{ flex: 1 }}>{schemaItem.content?.helpText || schemaItem.content.validation?.helpText}</Text>
            </View>
          }
          <View style={{ flexDirection: 'row', flex: 1, gap: 5 }}>
            <DropDownPicker
              items={currencyList}
              open={pickerOpen}
              onOpen={() => setHeight(DROPDOWN_HEIGHT)}
              onClose={() => setHeight(0)}
              zIndex={3}
              dropDownContainerStyle={{ flex: 1, borderColor: '#4791bd' }}
              dropDownDirection="AUTO"
              setOpen={setPickerOpen}
              value={pickerState}
              setValue={(value) => { setPickerState(value); helpers.setValue(value.value) }}
              placeholder='Currency'
              style={{ flex: 1, backgroundColor: theme ? 'white' : 'grey', maxWidth: "50%" }}
              containerStyle={{ flex: 1, minWidth: 100, maxWidth: "50%" }}
            />
            <InputFieldStyled
              value={value}
              ref={inputRef}
              multiline={schemaItem.content?.ref === 'multiline' ? true : false}
              onChangeText={(text) => { setLocalText(text); helpers.setValue(text) }}
              enablesReturnKeyAutomatically={true}
              enterKeyHint={index === length - 1 ? 'done' : 'next'}
              inputMode="numeric"
              style={{ flex: 1, color: theme ? 'black' : 'white', maxWidth: "50%" }}
              onFocus={() => { if (props.focusCallback) props.focusCallback() }}
              onSubmitEditing={() => { field.onBlur(schemaItem.title); helpers.setValue(value); helpers.setTouched(true, true); handleKeyPress(index); if (props.blurCallback) props.blurCallback() }}
              onEndEditing={() => { helpers.setValue(value); helpers.setTouched(true, true); if (props.blurCallback) props.blurCallback() }}
              onBlur={() => { field.onBlur(schemaItem.title); if (props.blurCallback) props.blurCallback() }}
              placeholder={schemaItem.validation?.placeHolder ? schemaItem.validation.placeHolder : parseByDelimiter(schemaItem.title)}
            />
          </View>
        </Animatable.View>
      )
    case 'number':
      return (
        <View style={{ overflow: 'hidden', ...props.customStyle }}>
          {
            (schemaItem.content?.helpText || schemaItem.content?.validation?.helpText) &&
            <View style={{ flexDirection: 'row', gap: 10, marginVertical: 5 }}>
              <AntDesign name="questioncircle" size={14} color={theme ? 'black' : 'white'} />
              <Text style={{ flex: 1 }}>{schemaItem.content?.helpText || schemaItem.content.validation?.helpText}</Text>
            </View>
          }
          <View style={{ flexDirection: 'row', flex: 1 }}>
            <TextField
              value={field.value}
              ref={inputRef}
              multiline={schemaItem.content?.ref === 'multiline' ? true : false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => { helpers.setValue(event.target.value) }}
              style={{ flex: 1, color: theme ? 'black' : 'white' }}
              onFocus={() => { if (props.focusCallback) props.focusCallback() }}
              onEndEditing={() => { helpers.setValue(value); helpers.setTouched(true, true); if (props.blurCallback) props.blurCallback() }}
              onBlur={() => { field.onBlur(schemaItem.title); if (props.blurCallback) props.blurCallback() }}
              placeholder={schemaItem.validation?.placeHolder ? schemaItem.validation.placeHolder : parseByDelimiter(schemaItem.title)}
            />
          </View>
        </View>
      );
    case 'dropDown':
      const enumArray = schemaItem.content?.enum || schemaItem.validation?.enum;
      const newItemArray = [];
      enumArray?.map(item => newItemArray.push({ label: toTitleCase(item), value: item }))
      return (
        <Animatable.View duration={100} transition={['flex', 'marginBottom', 'top']} style={{ flex: 1, marginBottom: height }
        }>
          <View style={{ flexDirection: 'row' }}>
            <Text style={fieldStyles.typeStyle}>{parseByDelimiter(schemaItem.title)}</Text>
            {hasError && <Animatable.Text animation={'fadeInLeft'} style={{ color: 'red', zIndex: -1, padding: 2 }}>{meta.error}</Animatable.Text>}
          </View>
          {
            schemaItem.content?.helpText &&
            <View style={{ flexDirection: 'row', gap: 10, marginVertical: 5 }}>
              <AntDesign name="questioncircle" size={14} color={theme ? 'black' : 'white'} />
              <Text style={{ flex: 1 }}>{schemaItem.content?.helpText}</Text>
            </View>
          }
          <DropDownPicker
            onOpen={() => setHeight(DROPDOWN_HEIGHT)}
            onClose={() => setHeight(0)}
            zIndex={3}
            dropDownContainerStyle={{ flex: 1, borderColor: '#4791bd' }}
            dropDownDirection="AUTO" key={schemaItem.title}
            style={[fieldStyles.dropDownStyle]}
            items={newItemArray} value={pickerState}
            setValue={(value) => { setPickerState(value); }}
            onSelectItem={(value) => { helpers.setValue(value.value, true); helpers.setTouched(true,true) }}
            open={pickerOpen}
            setOpen={setPickerOpen} />
        </Animatable.View>
      );
    case 'boolean':
      useEffect(() => {
        if (!field.value) {
          helpers.setValue(switchState);
        }
      }, [])
      return (
        <View style={[styles.validationOptionContainer, { marginVertical: 10 }]}>
          <Text style={{ fontWeight: 'bold', flex: 1 }}>{parseByDelimiter(schemaItem.title)}</Text>
          <Switch
            value={switchState}
            onValueChange={handleSwitchChange}
            style={{ alignSelf: 'center' }}
          />
        </View>
      );
    case 'website':
    case 'url':
      return (
        <TextField
          inputRef={inputRef}
          onError={()=> {
            meta.error = 'Invalid URL'
          }}
          onBlur={onBlur}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            helpers.setValue(event.target.value);
          }}
          fullWidth
          error={(field.value && !field.value.match(/(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/g)) || error}
          helperText="Enter a valid URL"
          margin="normal"
          placeholder={parseByDelimiter(toTitleCase(schemaItem.title))}
          label={parseByDelimiter(toTitleCase(schemaItem.title))}
          type="url"
          value={field.value ?? ''}
        />
      );
    case 'Mobile':
    case 'Phone':
    case 'mobile':
    case 'phone':
      return (
        <Animatable.View duration={100} transition={['flex', 'minHeight', 'marginBottom', 'top']} style={{ flex: 1, zIndex: 1000, minHeight: height, minWidth: "100%" }}>
          <PhoneInput inputStyle={{ minWidth: "100%" }}
            inputProps={{ ref: inputRef }} country={props.schemaItem.validation.default || 'uk'} dropdownStyle={{ zIndex: 1000, minWidth: "100%" }} containerStyle={{ zIndex: 10000 }} countryCodeEditable inputClass={inputRef} onFocus={() => { setHeight(400) }} onBlur={() => { onBlur(); setHeight(0) }} onChange={(value) => { helpers.setValue(value) }} value={field.value ?? ""} />
        </Animatable.View>
      )
    case 'checkbox':
      return (
        <View style={{ flex: 1, flexDirection: 'row', marginVertical: 10 }}>
          <Text style={{ flex: 1, fontWeight: 'bold' }}>{parseByDelimiter(schemaItem.title)}</Text>
          <Checkbox
            value={switchState}
            onValueChange={handleSwitchChange}
            style={{ alignSelf: 'center', left: -20 }}
          />
        </View>
      );
    case 'tags':
      return (
        <View style={{ flex: 1, maxWidth: 400 }}>
          <TagInput label={schemaItem.title} placeHolder='Add data tags, separated by Space' tags={tags} setTags={handleUpdateTags} enabled={true} />
        </View>
      );
    case 'barcode':
      return (
        <View style={{ flexDirection: 'row' }}>
          <Modal isVisible={barcode} onBackdropPress={() => setBarcode(false)} deviceHeight={height} deviceWidth={width} style={styles.modalContainer}>

          </Modal>
          <Pressable style={{ flex: 1 }} onPress={() => setBarcode(true)}>
            <Entypo name="camera" size={24} color="black" />
          </Pressable>
          <InputFieldStyled style={{ flex: 5 }} enterKeyHint='next' onBlur={onBlur(schemaItem.title)} multiline={schemaItem.content?.ref === 'multiline' ? true : false} onChange={helpers.setValue(schemaItem.title)} onChangeText={formRef.current?.onChange(schemaItem.title)} placeholder={parseByDelimiter(schemaItem.title)} />
        </View>
      );
    case 'city':
    case 'address':
    case 'location':
      return (
        <GenerateGetCity ref={inputRef} index={index} length={length} nextFieldFunction={nextFieldFunction as (index: number, length: number) => void} helpers={helpers} field={field} meta={meta} theme={theme} hasError={hasError as boolean | undefined} schemaItem={schemaItem} updateState={(address) => { setCity(address); helpers.setValue(address) }} updateFunction={handleUpdateCity} initialValue={''} />
      );
    case 'image':
      return (
        <View style={{ flex: 1, gap: 10, marginVertical: 20 }}>
          <View style={{ flexDirection: 'row', gap: 5 }}>
            <Text style={fieldStyles.typeStyle}>{parseByDelimiter(schemaItem.title)}</Text>
            {hasError && <Animatable.Text animation={'fadeInLeft'} style={{ color: 'red', zIndex: -1, padding: 2 }}>{meta.error}</Animatable.Text>}
          </View>
          {
            schemaItem.content?.helpText &&
            <View style={{ flexDirection: 'row', gap: 10, marginVertical: 5 }}>
              <AntDesign name="questioncircle" size={14} color={theme ? 'black' : 'white'} />
              <Text style={{ flex: 1 }}>{schemaItem.content?.helpText}</Text>
            </View>
          }
          {selectedImage && <><Image source={{ uri: selectedImage }} style={{ flex: 1, width: '100%', height: 200, alignSelf: 'center', padding: 20 }} /></>}
          <ImagePickerComponent onImageSelected={async (img) => await handleImageSelected(img)} />
        </View>
      );
    case 'dateRange':
      const [showSelector, setShowSelector] = useState(false);

      const selectionRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      }

      return (
        <Animatable.View transition={'height'} style={{ maxHeight: 80, zIndex: 100000, height: open ? 800 : undefined }}>
          <View style={{ flexDirection: "row" }}>
            <Text style={[fieldStyles.typeStyle, { flex: 1, marginBottom: 10, left: -5, textAlign: "left" }]}>Date Range</Text>
          </View>
          {
            showSelector &&
            <Modal
              isVisible={showSelector}
              style={{ position: "absolute", minHeight: 500, minWidth: 700 }}
            >
                <DateRangePicker
                  style={{ flex: 1, transform: [{ scale: 1.2 }] }}
                  ranges={[selectionRange]}
                  onChange={(ranges) => {
                    const { selection } = ranges;
                    setDateSelected(selection);
                    helpers.setValue({ startDate: selection.startDate, endDate: selection.endDate });
                    setShowSelector(null);
                  }}
              />
            </Modal>
          }
          {
            value ?
              <Pressable onPress={() => setShowSelector(true)} style={{ minHeight: 40 }} >
                {
                  dateSelected.startDate === dateSelected.endDate ?
                    <Text style={{ textAlign: "center", marginVertical: 20, borderWidth: 0.2, borderRadius: 10, flex: 1 }}>{moment(dateSelected.startDate).format("DD MMM YYYY")}</Text>
                    :
                    <View style={{ flexDirection: "row" }}>
                      <Button textStyle={{ fontSize: 12 }} textColor={theme ? "black" : "white"} customStyle={{ minHeight: 40, borderTopRightRadius: 0, borderBottomRightRadius: 0 }} defaultColor={theme ? "white" : "black"} buttonText={moment(dateSelected?.startDate).format('DD MM YYYY').toString()} onPress={() => null} />
                      <Button textStyle={{ fontSize: 12 }} textColor={theme ? "black" : "white"} customStyle={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} defaultColor={theme ? "white" : "black"} buttonText={moment(dateSelected?.endDate).format('DD MM YYYY').toString()} onPress={() => null} />
                    </View>

                }
              </Pressable>
              :
              <Button buttonText={"Select Date Range"} customStyle={{ minHeight: 40, flex: 1, borderRadius: 5, minWidth: "100%" }} onPress={() => setShowSelector(true)} />
          }
        </Animatable.View>
      );
    case 'date':
      return (
        <View style={{ flex: 1, marginBottom: 20 }}>
          <View style={{ flexDirection: "row" }}>
            <Text style={[fieldStyles.typeStyle, { flex: 1, marginBottom: 10, left: -5, textAlign: "left" }]}>{parseByDelimiter(toTitleCase(schemaItem.title))}</Text>
          </View>
          {
              Platform.OS === 'web' ?
                <View style={{ flex: 1 }}>
                  <DatePickerModal
                    locale="en"
                    mode="single"
                    visible={open}
                    onDismiss={()=> {onDismissSingle(); setTimeOpen(true); }}
                    date={moment().toDate()}
                    calendarIcon="calendar"
                    onConfirm={({ date }) => onConfirmSingle(date)}
                  />
                  <TimePickerModal
                    locale="en"
                    visible={time}
                    onDismiss={() => setTimeOpen(false)}
                    onConfirm={value => { handleTimeChange(value); setTimeOpen(false) }} />
                  {
                    dateSelected ?
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#ececec', paddingHorizontal: 10, marginVertical: 5, borderRadius: 20, padding: 5 }}>
                        <Text adjustsFontSizeToFit style={{ color: 'black', textAlign: 'left', fontSize: 16, fontWeight: '600', marginVertical: 4 }}>{moment(dateSelected).format('dddd DD MM YYYY')}</Text>
                        <Pressable onPress={() => { setDateSelected(null) }} onBlur={onBlur(schemaItem.title)}>
                          <Entypo name="calendar" size={24} color="black" style={{ textAlign: 'right' }} />
                        </Pressable>
                      </View>
                      :
                      <Button customStyle={{ borderRadius: 5, minWidth: "100%" }} defaultColor={buttonColor ? buttonColor : undefined} buttonText={"Select Date"} icon='calendar' onPress={() => setOpen(true)} />
                  }
                </View>
                :
                <View style={{ flex: 1 }}>
                  {
                    dateSelected ?
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 10, marginVertical: 5, borderRadius: 20, padding: 5 }}>
                        <Text adjustsFontSizeToFit style={{ textAlign: 'left', fontSize: 16, fontWeight: '600', marginVertical: 4 }}>{moment(dateSelected).format('dddd DD MM YYYY HH:MMa')}</Text>
                        <Pressable onPress={() => { setDateSelected(null) }} onBlur={onBlur(schemaItem.title)}>
                          <Entypo name="cross" size={24} color="black" style={{ textAlign: 'right' }} />
                        </Pressable>
                      </View>
                      :
                      <>
                        <DatePicker modal open={dateModal} onCancel={() => setDateModal(false)} onConfirm={(date) => { console.log(date), setDateSelected(date); handleDateSelection('', date); setDateModal(false) }} style={{ zIndex: 100 }} theme={theme ? "light" : "dark"} date={date} minuteInterval={15} onDateChange={(date) => { setDate(date); handleDateSelection(schemaItem?.content?.type, date) }} textColor={theme ? "black" : "white"} mode="datetime" />
                      <Button onPress={() => { setDateModal(true) }} customStyle={{ minHeight: 40, flex: 1, alignSelf: "stretch", maxWidth: undefined, borderRadius: 5, minWidth: "100%" }} buttonText={"Select Date and Time"} />
                      </>
                  }
                </View>
            }
        </View>
      );
    case 'dateTime':
      return (
        <View style={{ flex: 1, marginVertical: 20 }}>
          <View style={{ flexDirection: 'row', gap: 3 }}>
            <Text style={fieldStyles.typeStyle}>{parseByDelimiter(schemaItem.title)}</Text>
            {hasError && <Animatable.Text animation={'fadeInLeft'} style={{ color: 'red', zIndex: -1, padding: 2 }}>{meta.error}</Animatable.Text>}
          </View>
          <View style={{ flex: 1 }}>
            {
              Platform.OS === 'web' ?
                <View style={{ flex: 1, flexDirection: "row", flexWrap: "wrap" }}>
                  <DatePickerModal
                    locale="en"
                    mode="single"
                    visible={open}
                    onDismiss={()=> {onDismissSingle(); setTimeOpen(true); }}
                    date={moment().toDate()}
                    calendarIcon="calendar"
                    onConfirm={({ date }) => onConfirmSingle(date)}
                  />
                  <TimePickerModal
                    locale="en"
                    visible={time}
                    onDismiss={() => setTimeOpen(false)}
                    onConfirm={value => { handleTimeChange(value); setTimeOpen(false) }} />
                  {
                    dateSelected ?
                      <View style={{ flex: 3, flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#ececec', paddingHorizontal: 10, marginVertical: 5, borderRadius: 20, borderTopEndRadius: 0, borderBottomEndRadius: 0, padding: 5 }}>
                        <Text adjustsFontSizeToFit style={{ color: 'black', textAlign: 'left', fontSize: 16, fontWeight: '600', marginVertical: 4 }}>{moment(dateSelected).format('dddd DD MM YYYY')}</Text>
                        <Pressable onPress={() => { setDateSelected(null) }} onBlur={onBlur(schemaItem.title)}>
                          <Entypo name="calendar" size={24} color="black" style={{ textAlign: 'right', top: 5 }} />
                        </Pressable>
                      </View>
                      :
                      <Button customStyle={{ alignSelf: "stretch", maxWidth: undefined, minWidth: "100%", borderRadius: 5 }} iconStyle={{ top: 5 }} defaultColor={buttonColor ? buttonColor : undefined} buttonText={"Select Date"} icon='calendar' onPress={() => setOpen(true)} />
                  }

                  {
                    dateSelected && timeSelected ?
                      <View style={{ flex: 1, minWidth: 100, flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#ececec', paddingHorizontal: 10, borderTopStartRadius: 0, borderBottomStartRadius: 0, marginVertical: 5, borderRadius: 20, padding: 5, gap: 10 }}>
                        <Text adjustsFontSizeToFit style={{ color: 'black', textAlign: 'left', fontSize: 16, fontWeight: '600', marginVertical: 4 }}>{moment(timeSelected).format('HH:MMa')}</Text>
                        <Pressable onPress={() => { setTimeSelected(null) }} onBlur={onBlur(schemaItem.title)}>
                          <Entypo name="clock" size={24} color="black" style={{ textAlign: 'right', top: 5 }} />
                        </Pressable>
                      </View>
                      :
                      <View style={{ flex: 0.6, zIndex: -1 }}>
                        {dateSelected &&
                          <Animatable.View animation={'fadeIn'} style={{ flex: 1, top: 5 }}>
                            <Button customStyle={{ maxWidth: undefined, borderRadius: 5, minHeight: 48, minWidth: "100%", borderBottomStartRadius: 20, borderBottomEndRadius: 20, top: 5 }} iconOnly defaultColor={buttonColor ? buttonColor : undefined} icon='clock' onPress={() => setTimeOpen(true)} />
                          </Animatable.View>
                        }
                      </View>
                  }
                </View>
                :
                <View style={{ flex: 1 }}>
                  {
                    dateSelected ?
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 10, marginVertical: 5, borderRadius: 20, padding: 5 }}>
                        <Text adjustsFontSizeToFit style={{ textAlign: 'left', fontSize: 16, fontWeight: '600', marginVertical: 4 }}>{moment(dateSelected).format('dddd DD MM YYYY HH:MMa')}</Text>
                        <Pressable onPress={() => { setDateSelected(null) }} onBlur={onBlur(schemaItem.title)}>
                          <Entypo name="cross" size={24} color="black" style={{ textAlign: 'right' }} />
                        </Pressable>
                      </View>
                      :
                      <>
                        <DatePicker modal open={dateModal} onCancel={() => setDateModal(false)} onConfirm={(date) => { console.log(date), setDateSelected(date); handleDateSelection('', date); setDateModal(false) }} style={{ zIndex: 100 }} theme={theme ? "light" : "dark"} date={date} minuteInterval={15} onDateChange={(date) => { setDate(date); handleDateSelection(schemaItem?.content?.type, date) }} textColor={theme ? "black" : "white"} mode="datetime" />
                        <Button onPress={() => { setDateModal(true) }} customStyle={{ minHeight: 40, minWidth: 220, borderRadius: 5 }} buttonText={"Select Date and Time"} />
                      </>
                  }
                </View>
            }
          </View>
        </View>
      );
    default:
      return (
        <View style={{ height: 80, flex: 1, ...props.customStyle, minHeight: schemaItem.content?.ref === 'multiline' ? 200 : 80, paddingBottom: schemaItem.content?.ref === 'multiline' ? 20 : undefined, zIndex: -index }} key={'textThing'}>
          <View style={{ flexDirection: 'row', gap: 3 }}>
            {hasError && <Animatable.Text animation={'fadeInLeft'} style={{ color: 'red', zIndex: -1, padding: 2 }}>{meta.error}</Animatable.Text>}
          </View>
          {
            (schemaItem.content?.helpText || schemaItem.content?.validation?.helpText) &&
            <View style={{ flexDirection: 'row', gap: 10, marginVertical: 5 }}>
              <AntDesign name="questioncircle" size={14} color={theme ? 'black' : 'white'} />
              <Text style={{ flex: 1 }}>{schemaItem.content?.helpText || schemaItem.content.validation?.helpText}</Text>
            </View>
          }
          <TextField style={{ backgroundColor: "white", minHeight: schemaItem.content?.ref === 'multiline' ? 200 : undefined }} error={error} onBlur={onBlur} onBlurCapture={() => { helpers.setTouched(true, true) }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { helpers.setValue(event.target.value) }} fullWidth margin="normal" placeholder={parseByDelimiter(toTitleCase(schemaItem.title))} label={parseByDelimiter(toTitleCase(schemaItem.title))} type="text" value={field.value ?? ""} />
        </View>
      );
  }
}

const fieldStyles = StyleSheet.create({
  dropDownStyle: {
    borderColor: '#4791bd',
    backgroundColor: 'white',
    borderRadius: 5
  },
  containerStyle: {

  },
  typeStyle: {
    minHeight: 40,
    fontWeight: 'bold',
    color: '#00000070',
    paddingHorizontal: 5
  }
})

export default memo(HandleFieldType);