import { Platform, Pressable, ViewStyle } from 'react-native';
import { Text, View } from '@constants/Themed';
import styles from '../../../stylesheet';
import { forwardRef, useEffect, useState } from 'react';
import Ionicons from '@expo/vector-icons/Ionicons';
import * as Animatable from 'react-native-animatable';
import AntDesign from '@expo/vector-icons/AntDesign';
import * as LocalAuthentication from 'expo-local-authentication';
import { SHOW_AUTH_MODAL } from '@reduxLocal/actions/types';
import { DefaultRootState } from '@reduxLocal/persistState';
import { useDispatch, useSelector } from 'react-redux';
import React, { useRef, useImperativeHandle } from 'react';
import AchievementBackground from '../../../assets/--native/AchievementBackground';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
import { H2, H5, Separator, XStack, YStack } from 'tamagui';
import { isTablet } from 'react-native-device-info';
import SmallActionButton from '@components/UI/SmallActionButton';

type DropDownViewProps = {
  cardTitle?: string | JSX.Element,
  children: JSX.Element[] | JSX.Element,
  hideInitial?: boolean,
  theme?: boolean,
  secure?: boolean,
  minHeight?: number | string,
  keyString?: string,
  style?: ViewStyle,
  green?: boolean,
  color?: string,
  removeDrop?: boolean;
  fillAll?: boolean;
  subtitle?: string | JSX.Element;
  smallCard?: boolean;
  onVisibilityChange?: Function;
  removeBorder?: boolean;
  removeFlex?: boolean;
  helpText?: string;
  maxHeight?: number;
  disablePadding?: boolean;
  withShadow?: boolean;
  cardActions?: Array<{ icon: string | JSX.Element, function: (e: any) => void, color?: string }>;
  specialAction?: JSX.Element | null;
};

export type DropDownViewHandle = {
  toggleVisibility: () => void;
};

const DropDownView = forwardRef<DropDownViewHandle, DropDownViewProps>((props, ref) => {
  const [visible, setVisible] = useState(props.secure || props.hideInitial ? false : true);
  const theme = themeSelector();

const dispatch = useDispatch();
  useEffect(() => {
    if (props.hideInitial) {
      setVisible(false);
    }
  }, [props.hideInitial]);

  useImperativeHandle(ref, () => {
    return {
      toggleVisibility: () => {
        if (Platform.OS !== 'web' && props.secure) {
          try {
            if (!visible) {
              LocalAuthentication.authenticateAsync().then((res) => {
                if (res.success) {
                  setVisible(!visible);
                  if (props.onVisibilityChange) props.onVisibilityChange(!visible);
                } else {
                  dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Authentication failed', type: 'warning' } });
                  if (props.onVisibilityChange) props.onVisibilityChange(!visible);
                }
              });
            } else {
              setVisible(!visible);
              if (props.onVisibilityChange) props.onVisibilityChange(!visible);
            }
          } catch (e) {
            dispatch({ type: SHOW_AUTH_MODAL, data: { error: 'An error occurred', type: 'error' } });
          }
        } else {
          setVisible(!visible);
          if (props.onVisibilityChange) props.onVisibilityChange(!visible);
        }
      },
    };

  }, [visible]);

  const appTheme = useSelector((state: DefaultRootState) => state.app.theme.lightTheme);


  return (
    <Animatable.View
      transition={'minHeight'}
      key={props.keyString}
      // easing={'ease-in-cubic'}
      duration={150}
      // direction={'alternate'}
      style={{
        flex: !props.removeFlex && 1,
        alignSelf: 'stretch',
        elevation: 20,
        maxWidth: Platform.select({ web: undefined, default: "100%" }),
        minHeight: props.minHeight && visible ? (props.maxHeight && props.maxHeight < props.minHeight ? props.maxHeight : props.minHeight) : (visible ? 400 : 120),
        minWidth: Platform.select({ web: '100%', default: undefined }),
        maxHeight: props.maxHeight ? props.maxHeight : (visible ? '100%' : Platform.select({ web: undefined, default: 120 })),
        overflow: Platform.select({ web: 'hidden', default: undefined }),
        ...props.style,
      }}>
      <View
        style={[
          styles.noteContainer,
          {
            flex: 1,
            overflow: 'hidden',
            backgroundColor:'transparent',
            flexDirection: 'column',
            minWidth: props.fillAll ? '105%' : '100%',
            paddingVertical: 10,
            marginVertical: 10,
            borderRadius: props.removeBorder ? 0 : 20,
          },
        ]}>
        <AchievementBackground
          green={props.green ? true : false}
          style={{
            minHeight: '200%',
            position: 'absolute',
            minWidth: visible ? '300%' : '100%',
            overflow: 'hidden',
            opacity: 1,
            zIndex: -1,
            transform: [{ scale: Platform.OS === 'web' ? 1.2 : (visible ? (isTablet() ? 8 : 1.2) : (isTablet() ? 20 : 7)) }],
          }}
        />
        <View
          style={{
            minWidth: Platform.select({ default: "100%", web: "100%" }),
            flex: 1,
            borderRadius: 80,
            overflow: 'visible',
            paddingHorizontal: Platform.select({ default: 0, ios: 0, android: 0 }),
            minHeight: Platform.OS === 'web' ? 70 : 30,
            backgroundColor: 'transparent',
          }}>
          <Pressable
            disabled={props.removeDrop ? true : false}
            onPress={() => {
              if (!props.secure) {
                setVisible(!visible);
                if (props.onVisibilityChange) props.onVisibilityChange(!visible);
              } else {
                if (Platform.OS !== 'web') {
                  try {
                    if (!visible) {
                      LocalAuthentication.authenticateAsync().then((res) => {
                        if (res.success) {
                          setVisible(!visible);
                          if (props.onVisibilityChange) props.onVisibilityChange(!visible);
                        } else {
                          dispatch({ type: SHOW_AUTH_MODAL, data: { content: 'Authentication failed', type: 'warning' } });
                          if (props.onVisibilityChange) props.onVisibilityChange(!visible);
                        }
                      });
                    } else {
                      setVisible(!visible);
                      if (props.onVisibilityChange) props.onVisibilityChange(!visible);
                    }
                  } catch (e) {
                    dispatch({ type: SHOW_AUTH_MODAL, data: { error: 'An error occurred', type: 'error' } });
                  }
                } else {
                  setVisible(!visible);
                  if (props.onVisibilityChange) props.onVisibilityChange(!visible);
                }
              }
            }}
            style={{ minWidth: "100%", flex: 1, flexDirection: 'row', alignSelf: 'stretch', padding: 5 }}>
            <View style={{ flexDirection: 'row', flex: 1, backgroundColor: 'transparent', maxHeight: 100, paddingHorizontal: 10 }}>
              <H2
                numberOfLines={1}
                userSelect="none"
                style={{
                  fontWeight: '600',
                  textAlign: 'left',
                  flex: 3,
                  letterSpacing: 0.7,
                  font: "-moz-initial",
                  fontSize: Platform.select({ default: 18, ios: 12, android: 12 }),
                  overflow: 'visible',
                  color: visible ? 'white' : 'white',
                  minWidth: '90%',
                  minHeight: 20,
                  flexWrap:"wrap"
                }}>
                {props.cardTitle}
                <Separator borderColor={"white"} style={{ top: -5, color: "white" }} />
              </H2>
              {props.secure && Platform.OS != 'web' ? (
                <View style={{ flex: 0.4 }}>
                  <Ionicons name="lock-open" size={16} color="white" />
                </View>
              ) : (
                <></>
              )}
            </View>
            
          </Pressable>
          {props.subtitle && (
            <View style={{
              top: Platform.select({ web: 30 }),
              flex: 1, alignSelf: "stretch", marginVertical: 5, backgroundColor: 'transparent'
            }}>
              <H5 numberOfLines={2} style={{ flexWrap: "wrap", fontStyle: 'italic', color: 'white', fontSize: Platform.select({ default: undefined, ios: 8, android: 8 }) }}>{props.subtitle}</H5>
            </View>
          )}
          {
            props.helpText && !visible &&
            (
              <View style={{ flex: 1, marginVertical: 5, backgroundColor: 'transparent', overflow: "hidden", paddingHorizontal: 15, paddingRight: 30 }}>
                <Animatable.Text animation={'slideInDown'} style={{ fontStyle: 'italic', color: 'white', fontSize: Platform.select({ default: undefined, ios: 10 }) }}>{props.helpText}</Animatable.Text>
              </View>
            )
          }
          
        </View>
        <View
          style={{ flex: visible ? 15 : 0.01, gap: 5, flexDirection: "row", backgroundColor: "transparent", minHeight: visible ? 200 : 0, minWidth: Platform.select({ web: "97%", default: "100%" }), maxHeight: visible ? '100%' : 0, height: visible ? '100%' : 0, }}>
          {visible || props.removeDrop ? (
            <XStack flex={1} gap={10}>
              <View style={{ flex: 10, alignItems: "center", alignSelf: "stretch", justifyContent: "center", left: Platform.select({ web: 0, default: 17 }), top: Platform.select({ default: undefined, web: -10 }), shadowRadius: 20, shadowOpacity: props.withShadow ? 0.15 : 0, paddingBottom: props.disablePadding ? undefined : 40, minHeight: '100%', alignContent: 'flex-start', padding: props.disablePadding ? 0 : 25, backgroundColor: appTheme ? 'white' : 'grey', borderRadius: 20, paddingVertical: 10, maxWidth: isTablet() ? "97%" : Platform.select({ web: "101%", default: "90%" }), overflow: 'hidden', paddingRight: 20 }}>
              {props.children}
            </View>
              {
                props.cardActions && props.cardActions.length > 0 && (
                  <YStack gap={10} style={[styles.centerEverything, {  top: -10, flex: 1, maxWidth: 40 }]}>
                    {props.cardActions.map((action, index) => (
                      <SmallActionButton noBackground key={index + 'item._id'} action={action} index={index} color={'white'} />
                    ))}
                  </YStack>
                )
              }
            </XStack>
          ) : null}
        </View>
      </View>
      {!props.removeDrop && (
        <View style={{ top: 20, position: "absolute", right: 10 }}>
          {visible ? (
            <View style={{ flex: 1, backgroundColor: 'transparent' }}>
              <AntDesign name="up" size={24} color="white" style={{ alignSelf: 'flex-end' }} />
            </View>
          ) : (
            <View style={{ flex: 1, backgroundColor: 'transparent' }}>
              <AntDesign name="down" size={24} color="white" style={{ alignSelf: 'flex-end' }} />
            </View>
          )}
        </View>
      )}
    </Animatable.View>
  );
});

export default DropDownView;
