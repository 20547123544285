import React, { useState, useEffect, useMemo } from 'react';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ColorSchemeName, StatusBar as DeviceStatusBar, Dimensions, Platform, Pressable, Image, ScrollView, FlatList } from 'react-native';
import { LoginStackParamList, RootStackParamList } from './types';
import { View, Text } from '@constants/Themed';
import LinkingConfiguration from './LinkingConfiguration';
import { navigationRef } from './rootNavigation';
import { useDispatch, useSelector } from 'react-redux';
import AuthModal from '../components/modals/ErrorModal/AuthModal';
import DashboardNavigator from './NavigationStacks/DashStack';
import LoadingModal from '../components/modals/LoadingModal/LoadingModal';
import LoadingScreen from './screens/LoadingScreen/loadingScreen';
import LoginScreen from './screens/TopLevel/LoginScreen';
import styles from '../../stylesheet';
import RegisterModal from '../components/modals/RegisterModal/RegisterModal';
import { StoreStackNavigator } from './NavigationStacks/StoreStack';
import NotFoundScreen from './screens/TopLevel/NotFoundScreen';
import TCModal from '../components/modals/TCModal/TCModal';
import FormScreen from './Form/FormScreen';
import UserProfileScreen from './screens/IndexLevel/UserProfileDisplay/UserProfileScreen';
import LiveReports from './screens/IndexLevel/LiveReportScreen/LiveReports';
import { useTranslation } from 'react-i18next';
import { CHANGE_LANGUAGE, CHANGE_THEME } from '@reduxLocal/actions/types';
import { SafeAreaProvider, useSafeAreaInsets } from 'react-native-safe-area-context';
import { isTablet } from 'react-native-device-info';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import { TamaguiProvider } from 'tamagui';
import appConfig from '../../tamagui.config';
import { themeSelector } from '@reduxLocal/selectors/themeSelector';
const navTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    card: 'black',
    view: 'white',
    notification: 'white',
    text: 'black',
      background: Platform.OS === 'web' ? 'white' : '#ececec'
    },
  };

const darkTheme = {
  ...DarkTheme,
  colors: {
    ...DarkTheme.colors,
    text: 'white',
    background: Platform.OS === 'web' ? 'transparent' : '#444444'
  }
}

const Navigation = ({ colorScheme, hasUpdatePending }: { colorScheme: ColorSchemeName, hasUpdatePending: boolean }) => {
  const LoginStack = createNativeStackNavigator<LoginStackParamList>();
  const userLanguage = navigator.language || navigator.userLanguage;
  const dispatch = useDispatch();
    const theme = themeSelector();
    const insets = useSafeAreaInsets();

    useEffect(() => {
      console.log('original color schema', colorScheme);
      if (colorScheme != theme && Platform.OS != 'web') {
        console.log('theme mismatch, correcting');
        dispatch({
          type: CHANGE_THEME, payload: {
            lightTheme: colorScheme === 'light' ? true : false,
            theme: colorScheme
          }
        });
      }
    }, [colorScheme])

    useEffect(() => {
      dispatch({ type: CHANGE_LANGUAGE, payload: userLanguage })
    }, [])


    return (
      <TamaguiProvider defaultTheme={theme ? "light" : "dark"} config={appConfig}>
        <NavigationContainer ref={navigationRef} linking={LinkingConfiguration}
          theme={colorScheme === 'dark' ? darkTheme : navTheme}
        >
          <BottomSheetModalProvider>
            <View style={[styles.loginContainer, { top: Platform.select({ default: (isTablet() ? undefined : Platform.select({ android: -30, default: 65 })), web: undefined }), paddingBottom: Platform.select({ web: undefined, default: insets.bottom, }), marginBottom: Platform.select({ android: -5, default: 70, web: undefined }), flex: 1, zIndex: -1, flexDirection: 'row', paddingTop: Platform.OS != 'web' && Dimensions.get('window').width > 800 ? 35 : 0 }]}>
              <View style={{ flex: 24 }}>
                <LoginStack.Navigator screenOptions={{ headerShown: false, gestureEnabled: false }} >
                  <LoginStack.Screen name='LoadingScreen' options={{ title: "SmartSaaS Login" }} component={LoadingScreen} />
                  <LoginStack.Screen name='Dashboard' options={{ animation: "fade", title: "Dashboard" }} component={DashboardNavigator} initialParams={{ updatePending: hasUpdatePending }} />
                  <LoginStack.Screen name="Login" component={LoginScreen} navigationKey='login' />
                  <LoginStack.Screen name="Store" component={StoreStackNavigator} navigationKey='store' />
                  <LoginStack.Screen name="Form" component={FormScreen} />
                  <LoginStack.Screen name="NotFound" component={NotFoundScreen} />
                  <LoginStack.Screen name="UserProfile" component={UserProfileScreen} navigationKey='userprofile' />
                  <LoginStack.Screen name="LiveReports" component={LiveReports} navigationKey='livereports' />
                </LoginStack.Navigator>
              </View>
              <LoadingModal />
              <RegisterModal />
              <TCModal />
            </View>
          </BottomSheetModalProvider>
        </NavigationContainer>
        <AuthModal navigation={navigationRef} />
      </TamaguiProvider>
    )
  }

export default Navigation;

