import * as Updates from 'expo-updates';

let Config = {
  apiUrl: 'http://192.168.15.93:4000',
  webApi: __DEV__ ? 'http://localhost:4000' : "https://api.smartsaas.pro",
  aiAPI: __DEV__ ? "https://ai.smartsaas.pro:11437" : "https://ai.smartsaas.pro:11437",
  aiChatAPI: __DEV__ ? "https://ai.smartsaas.pro:11436" : "https://ai.smartsaas.pro:11436",
  enableHiddenFeatures: true,
};

if (Updates.channel === 'production') {
  Config.apiUrl = 'https://api.smartsaas.pro';
  Config.webApi = 'https://api.smartsaas.pro';
  Config.enableHiddenFeatures = false;
} else if (Updates.channel === 'staging') {
  Config.apiUrl = 'https://api.staging.com';
  Config.enableHiddenFeatures = true;
}

export default Config;
