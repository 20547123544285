import { createStore, applyMiddleware } from 'redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistStore, persistReducer } from 'redux-persist';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';

// Import your existing rootReducer from './reducers'
import rootReducer from './reducers';
import { configureStore } from '@reduxjs/toolkit';
import { Platform } from 'react-native';

export const fetchLogs = async () => {
  if (Platform.OS != 'web') return;  
  const db = await initDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction('logs', 'readonly');
    const store = transaction.objectStore('logs');
    const request = store.getAll();

    request.onsuccess = () => resolve(request.result);
    request.onerror = (event) => reject(event.target.error);
    transaction.oncomplete = () => db.close();
  });
};

// Initialize IndexedDB
const initDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('LogDB', 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore('logs', { autoIncrement: true });
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

// Save log to IndexedDB
export const saveLog = async (log) => {
  const db = await initDB();
  const transaction = db.transaction('logs', 'readwrite');
  const store = transaction.objectStore('logs');
  store.add(log);
  transaction.oncomplete = () => db.close();
};

export const clearDB = async () => {
  const db = await initDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction('logs', 'readwrite');
    const store = transaction.objectStore('logs');
    const request = store.clear();

    request.onsuccess = () => {
      resolve();
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };

    transaction.oncomplete = () => db.close();
  });
};


const apiLogger = store => next => action => {
  if (typeof action === 'function') {
    const log = {
      type: 'API_CALL',
      action: action.name,
      timestamp: new Date().toISOString(),
    };

    action(store.dispatch, store.getState)
      .then(response => {
      if (Platform.OS === 'web') {
        log.response = response;
        log.duration = new Date().getTime() - new Date(log.timestamp).getTime();
        saveLog(log);
      }
    }).catch(error => {
      if (Platform.OS === 'web') {
        log.error = error;
        log.duration = new Date().getTime() - new Date(log.timestamp).getTime();
        saveLog(log);
      }
    });

    return next(action);
  } else {
    const log = {
      type: 'ACTION',
      action: action.type,
      payload: action.payload,
      timestamp: new Date().toISOString(),
    };

    log.duration = new Date().getTime() - new Date(log.timestamp).getTime();
    if (!action.type.includes('persist') && Platform.OS != 'web') {
      saveLog(log);
    }
  }
  return next(action);
};

// Define your middleware, including Redux Flipper in development
const middlewares = Platform.OS != 'web' ? [] : [
  apiLogger
];

// if (__DEV__) {
//   const createDebugger = require('redux-flipper').default;
//   middlewares.push(createDebugger());
// }

// Redux Persist configuration
const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  blacklist: ['modal', 'lastAction', 'uploadPreview', 'notifications', 'toolbar', 'modals', 'report'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const initialState = rootReducer(undefined, {type: 'INITIAL_STATE'})
export type DefaultRootState = ReturnType<typeof store.getState>
// Create the Redux store
const store = configureStore({
  reducer: persistedReducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
  devTools: __DEV__ ? true : false
});

// Persistor for use with Redux Persist
const persistor = persistStore(store);

export { store, persistor };
